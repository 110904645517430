.pagination {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;
  font-size: 1.2rem;
  gap: 5px;
  margin: 40px 0px;
}

.pagination .page-num {
  padding: 8px 8px;
  cursor: pointer;
  border-radius: 3px;
  font-weight: 400px;
}

.pagination .page-num:hover {
  background-color: #000000;
  color: #ffff;
}
.pagination .page-num {
  background-color: #e4e4e4;
  color: rgb(0, 0, 0);
  border: #000000;
}

.pagination .active {
  background-color: #000000;
  color: #ffffff;
}

main {
  height: 70vh;
  /* height: 500px; */
  background-image: url("./img/portada.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom 40px right 0px;
  /* background-position: -10px 115%; */
  /* background-position: 0 -100px; */
}

/* .curved {
  position: relative;
  background: #2c3e50;
  height: 10vh;
  display: none;
} */
.curved-container {
  position: relative;
  /* top: 10px; */
}

.font-main-h1 {
  font-size: clamp(1rem, 7vw, 3rem);
}

.font-main-p {
  font-size: clamp(1rem, 4vw, 1.8rem);
}

.font-main-icon {
  font-size: clamp(0.5rem, 3vw, 1.2rem);
}

.font-carrucel-h2 {
  font-size: clamp(2.5rem, 5vw, 4rem);
}

.font-carrucel-h3 {
  font-size: clamp(2.5rem, 5vw, 4rem);
}

.font-carrucel-p {
  font-size: clamp(1rem, 4vw, 1.5rem);
}

.font-singleproperti-h3 {
  font-size: clamp(1.1rem, 3vw, 1.5rem);
}

.font-Announcement-text {
  font-size: clamp(0.6rem, 2vw, 0.8rem);
}

.curved {
  content: "";
  border-top-left-radius: 300% 100%;
  border-top-right-radius: 300% 100%;
  position: absolute;
  /* bottom: 10px; */
  bottom: 100px;

  bottom: 0;
  width: 100%;
  background: #ffff;
  height: 10vh;
}

.carrucel {
  background-image: url("./img/fondo.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  /* margin-left: 300px; */
  /* background-color: blue; */
}

.image {
  /* max-width: 80%; */
}

.singleimage {
  height: 70vh;
  margin: 20px 0px;
}

.arrow {
  /* margin: auto; */
  /* padding: 15px 20px; */
  border-radius: 10px;
  box-shadow: 0 0 5px 3px #ccc;
  /* display: flex; */
  /* width: 50px; */
  position: absolute;
  top: 300px;
  z-index: 100;
  color: rgb(100, 100, 100);
  color: white;
  cursor: pointer;
}

.arrow-right {
  right: -50px;
}

.arrow-left {
  left: -50px;
}

.img-grid {
  height: 200px;
}

/* .test {
  position: absolute;
  bottom: 0px;
  right: 0px;
} */

.whatsapp {
  max-width: 5rem;
  position: fixed;
  bottom: 10px;
  right: 30px;
  border: none;
  background: none;
}

.whatsappResponsive {
  max-width: 5rem;
  position: fixed;
  bottom: 10px;
  right: 30px;
}

.whatsappResponsive {
  max-width: 5rem;
  position: fixed;
  bottom: 0px;
  right: 10px;
}

/* CARDS USERS */

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  gap: 10px;
}
.cardStyle {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  transition: box-shadow 0.3s ease-out, transform 0.3s ease-out;
  transition-delay: 0.1s;
  cursor: pointer;
}

.cardStyle:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgb(162, 162, 162);
  opacity: 0.1;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

.cardStyle:hover {
  box-shadow: rgba(45, 45, 45, 0.05) 0px 2px 2px,
    rgba(49, 49, 49, 0.05) 0px 4px 4px, rgba(42, 42, 42, 0.05) 0px 8px 8px,
    rgba(32, 32, 32, 0.05) 0px 16px 16px, rgba(49, 49, 49, 0.05) 0px 32px 32px,
    rgba(35, 35, 35, 0.05) 0px 64px 64px;
  transform: translate(0, -0.4rem);
}

.cardStyle:hover:after {
  opacity: 0;
}

/* Agentes Slider */
.agentesSlider {
  display: none;
}

/* CARDS PROPIEDADES */

.img-propiedad {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.cardProperty {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  transition: box-shadow 0.3s ease-out, transform 0.3s ease-out;
  transition-delay: 0.1s;
  cursor: pointer;
}

.cardProperty:after {
  content: "";
  /* position: absolute; */
  width: 100%;
  height: 100%;
  background-color: rgb(162, 162, 162);
  opacity: 0.1;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

.cardProperty:hover {
  box-shadow: rgba(45, 45, 45, 0.05) 0px 2px 2px,
    rgba(49, 49, 49, 0.05) 0px 4px 4px, rgba(42, 42, 42, 0.05) 0px 8px 8px,
    rgba(32, 32, 32, 0.05) 0px 16px 16px, rgba(49, 49, 49, 0.05) 0px 32px 32px,
    rgba(35, 35, 35, 0.05) 0px 64px 64px;
  transform: translate(0, -0.4rem);
}

.cardProperty:hover:after {
  opacity: 0;
}

/* SEARCH FORM */

.searchForm {
  position: sticky;
  top: 20%;
}

@media (max-width: 1215px) {
  .nav-contac {
    display: none;
  }
}

@media (max-width: 1200px) {
  .singleimage {
    height: auto;
  }
}
@media (max-width: 1200px) {
  .arrow {
    top: 200px;
  }
}

@media (max-width: 1075px) {
  /* .ocultar {
    display: none;
  } */

  .agentesSlider {
    display: block;
  }
}

@media (max-width: 767px) {
  .arrow {
    top: 150px;
  }
  .ocultar {
    display: none;
  }

  /* .ocultar {
    display: none;
  } */
}
